import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type CardTextProps = HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean
    removePaddingX?: boolean
    removePaddingY?: boolean
    biggerPadding?: boolean
    border?: boolean
}

const CardText = ({
    children,
    removePaddingX,
    removePaddingY,
    className,
    disabled,
    ...props
}: PropsWithChildren<CardTextProps>) => {
    return (
        <div
            {...props}
            className={buildClassesWithDefault(
                {
                    'card-text': true,
                    '!py-0': removePaddingY,
                    '!px-0': removePaddingX,
                    disabled
                },
                className
            )}
        >
            {children}
            {disabled && <div className='disabled-overlay' />}
        </div>
    )
}

CardText.displayName = 'CardText'

export default CardText
