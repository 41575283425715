import { NumericFormat } from 'react-number-format'
import { NumericFormatProps } from 'react-number-format/types/types'
import { mergeRefs } from 'react-merge-refs'
import React, { FocusEvent, ForwardedRef, forwardRef, useRef } from 'react'
import TextField, { TextFieldProps } from '../text-field/TextField'

export type NumericInputProps = Omit<NumericFormatProps<TextFieldProps>, 'customInput' | 'getInputRef'>

const NumericInput = (
    { onFocus: onFocusProp, onBlur: onBlurProp, ...props }: NumericInputProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const inputRef = useRef<HTMLInputElement>()
    const previousValue = useRef<string>()

    const onFocus = (event: FocusEvent<HTMLInputElement>) => {
        onFocusProp?.(event)

        const value = inputRef.current.value
        if (parseFloat(value) === 0) {
            previousValue.current = inputRef.current.value
            inputRef.current.value = ''
        }
    }

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        onBlurProp?.(event)
        const value = inputRef.current.value
        if (!value.length && previousValue.current) {
            inputRef.current.value = previousValue.current
        }
    }

    return (
        <NumericFormat
            {...props}
            customInput={TextField}
            getInputRef={mergeRefs([ref, inputRef])}
            decimalSeparator=','
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
}

export default forwardRef(NumericInput)
