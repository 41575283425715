import { FloatingFocusManager, FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { buildClasses } from '../../../utils/StyleHelper'
import { usePopoverContext } from './Popover.context'
import React, { ForwardedRef, HTMLAttributes, forwardRef } from 'react'

export type PopoverContentProps = HTMLAttributes<HTMLDivElement> & {
    disableInitialFocus?: boolean
    zIndex?: number
}

const PopoverContent = (
    { disableInitialFocus, zIndex = 1, ...props }: PopoverContentProps,
    refProp: ForwardedRef<HTMLDivElement>
) => {
    const { context: floatingContext, ...context } = usePopoverContext()
    const ref = useMergeRefs([context.refs.setFloating, refProp])

    const styles = {
        zIndex,
        position: context.strategy,
        top: context.y ?? 0,
        left: context.x ?? 0,
        width: 'max-content',
        ...props.style
    }

    return (
        <FloatingPortal>
            {context.open && (
                <FloatingFocusManager
                    context={floatingContext}
                    modal={context.modal}
                    initialFocus={disableInitialFocus ? -1 : 0}
                >
                    <div
                        ref={ref}
                        style={styles}
                        {...context.getFloatingProps({
                            ...props,
                            className: buildClasses('popover', props.className)
                        })}
                    >
                        {props.children}
                    </div>
                </FloatingFocusManager>
            )}
        </FloatingPortal>
    )
}
export default forwardRef(PopoverContent)
