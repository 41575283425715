import { Column, RowData, Table } from '@tanstack/react-table'
import { Filter, FilterType } from '../../../../utils/ApiHelper'
import { XIcon } from '@heroicons/react/outline'
import { formatDateToIso, stringToDate } from '../../../../utils/DateHelper'
import BooleanSelect from '../../../dashboard/select/boolean-select/BooleanSelect'
import DatePicker from '../../date-picker/DatePicker'
import NumberRange from '../../number-range/NumberRange'
import React from 'react'
import TextField from '../../text-field/TextField'
import Tooltip from '../../tooltip/Tooltip'

export type TableFilterProps<TData extends RowData> = {
    column: Column<TData>
    table: Table<TData>
}

export type FilteringType<TData extends RowData> = FilterType | ((props: TableFilterProps<TData>) => React.ReactNode)

const TableFilter = <TData extends RowData>({ column, table }: TableFilterProps<TData>) => {
    const columnFilterValue = column.getFilterValue()
    const meta = column.columnDef.meta as Record<string, any>
    const filterTooltip = meta?.filterTooltip
    const filtering: FilteringType<TData> = (meta?.filtering as FilteringType<TData> | undefined) || 'string'

    if (typeof filtering === 'function') {
        return filtering({ column, table }) as JSX.Element
    }

    if (filtering === 'string') {
        const dateRangeFilterValue = columnFilterValue as Filter | undefined
        const stringValue = dateRangeFilterValue?.value ?? ''

        const textField = (
            <TextField
                inputClassName='!border-none'
                placeholder='Vyhledat'
                size='sm'
                srOnlyLabel
                label={column.columnDef.id}
                value={stringValue}
                onChange={({ target: { value } }) => {
                    table.setPageIndex(0)
                    column.setFilterValue({
                        type: 'string',
                        value
                    })
                }}
            />
        )

        return (
            <div className='relative'>
                {filterTooltip ? (
                    <Tooltip content={filterTooltip}>
                        <div>{textField}</div>
                    </Tooltip>
                ) : (
                    textField
                )}
            </div>
        )
    }

    if (filtering === 'number') {
        const numberRangeFilterValue = columnFilterValue as Filter<'number'> | undefined
        const numberRangeValue = numberRangeFilterValue?.value ?? undefined

        return (
            <NumberRange
                size='sm'
                decimalScale={2}
                placeholder='od - do'
                clearLabel='Vyčistit'
                saveLabel='Uplatnit'
                title='Filtrovat rozsah'
                fromLabel='Od'
                toLabel='Do'
                srOnlyLabel
                label={column.columnDef.id}
                value={numberRangeValue}
                onChange={value => {
                    if (!value) {
                        column.setFilterValue(null)
                        return
                    }
                    table.setPageIndex(0)
                    column.setFilterValue({
                        type: 'number',
                        value
                    })
                }}
            />
        )
    }

    if (filtering === 'date') {
        const dateRangeFilterValue = columnFilterValue as Filter<'date'> | undefined
        const startDateValue = dateRangeFilterValue?.value?.from
        const endDateValue = dateRangeFilterValue?.value?.to
        const startDate = startDateValue ? stringToDate(startDateValue) : undefined
        const endDate = endDateValue ? stringToDate(endDateValue) : undefined

        const clearDates = () => {
            column.setFilterValue(null)
        }

        return (
            <DatePicker
                dateFormat='dd.MM.yyyy'
                placeholderText='Datum'
                textFieldProps={{
                    size: 'sm',
                    srOnlyLabel: true,
                    enableRightIconPointerEvents: true,
                    rightIcon: (
                        <button className='text-secondary hover:cursor-pointer' onClick={clearDates}>
                            <XIcon />
                        </button>
                    )
                }}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={dateRange => {
                    table.setPageIndex(0)
                    column.setFilterValue({
                        type: 'date',
                        value: {
                            from: formatDateToIso(dateRange[0], 'date'),
                            to: dateRange[1] ? formatDateToIso(dateRange[1], 'date') : undefined
                        }
                    })
                }}
                selectsRange={true}
                label={column.columnDef.id}
            />
        )
    }

    if (filtering === 'boolean' || filtering === 'num-boolean') {
        const dateRangeFilterValue = columnFilterValue as Filter<'boolean'> | Filter<'num-boolean'> | undefined
        const booleanValue = dateRangeFilterValue?.value

        return (
            <BooleanSelect
                size='sm'
                isClearable
                srOnlyLabel
                label={column.columnDef.id}
                type={filtering === 'num-boolean' ? 'number' : 'boolean'}
                value={booleanValue as boolean | number}
                onChange={value => {
                    table.setPageIndex(0)
                    column.setFilterValue({
                        type: filtering,
                        value
                    })
                }}
            />
        )
    }

    return null
}

export default TableFilter
