import { PopoverProps } from './Popover'
import {
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react'
import { useMemo, useState } from 'react'

const usePopover = ({
    initialOpen = false,
    placement = 'bottom',
    modal,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    openOnHover = false
}: PopoverProps = {}) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState<boolean>(initialOpen)

    const open = controlledOpen ?? uncontrolledOpen
    const setOpen = setControlledOpen ?? setUncontrolledOpen

    const { context, ...data } = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: 'end'
            }),
            shift({ padding: 5 })
        ]
    })

    const hover = useHover(context, { enabled: openOnHover, handleClose: safePolygon() })
    const click = useClick(context)
    const dismiss = useDismiss(context)
    const focus = useFocus(context)
    const role = useRole(context)
    const interactions = useInteractions([click, dismiss, role, focus, hover])

    return useMemo(() => {
        return {
            open,
            setOpen,
            ...interactions,
            context,
            ...data,
            modal
        }
    }, [open, setOpen, interactions, context, data, modal])
}

export default usePopover
