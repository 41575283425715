import { usePopoverContext } from './Popover.context'
import React, { ForwardedRef, HTMLAttributes, ReactNode, forwardRef, isValidElement } from 'react'

type PopoverCloseChildren = ReactNode | ((close: () => void) => ReactNode)

export type PopoverCloseProps = Omit<HTMLAttributes<HTMLElement>, 'children'> & {
    children: PopoverCloseChildren
}

const PopoverClose = ({ children, ...props }: PopoverCloseProps, ref: ForwardedRef<HTMLElement>) => {
    const { setOpen } = usePopoverContext()

    const close = () => {
        setOpen(false)
    }

    if (typeof children === 'function') {
        return <>{children(close)}</>
    }

    if (!isValidElement(children)) {
        throw new Error('PopoverClose - Button must be a component.')
    }

    return React.cloneElement(children, {
        ...props,
        ...{
            ref: ref,
            onClick: event => {
                props.onClick?.(event)
                close()
            }
        }
    })
}

export default forwardRef(PopoverClose)
