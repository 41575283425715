import { GroupBase } from 'react-select'
import { translateBoolean } from '../../../../utils/TranslationHelper'
import { useTranslation } from 'next-i18next'
import React, { Ref, forwardRef, useMemo } from 'react'
import SelectField, { SelectFieldProps, SelectFieldRefType } from '../../../base/select-field/SelectField'

type BooleanSelectOption = {
    value: number | boolean
}

type BooleanSelectType = 'number' | 'boolean'

export type BooleanSelectProps = Omit<
    SelectFieldProps<BooleanSelectOption>,
    'getOptionValue' | 'getOptionLabel' | 'options' | 'onChange' | 'value'
> & {
    value?: boolean | number
    onChange?: (value?: boolean | number) => void
    type?: BooleanSelectType
}

const BooleanSelect = (
    {
        value: valueProp,
        onChange,
        type = typeof valueProp === 'boolean' ? 'boolean' : 'number',
        ...props
    }: BooleanSelectProps,
    ref: Ref<SelectFieldRefType<BooleanSelectOption, GroupBase<BooleanSelectOption>, false>>
) => {
    const { t } = useTranslation('common')

    const options = useMemo<BooleanSelectOption[]>(() => {
        if (type === 'boolean') {
            return [
                {
                    value: true
                },
                {
                    value: false
                }
            ]
        }
        return [
            {
                value: 1
            },
            {
                value: 0
            }
        ]
    }, [type])

    const value = useMemo<BooleanSelectOption>(() => {
        const boolValue = options.find(item => {
            return item.value === valueProp
        })
        if (typeof boolValue === 'undefined') {
            return null
        }
        return boolValue
    }, [options, valueProp])

    return (
        <SelectField
            {...props}
            ref={ref}
            options={options}
            value={value}
            getOptionLabel={val => {
                return translateBoolean(t, val.value)
            }}
            getOptionValue={val => {
                return val.value as unknown as string
            }}
            onChange={newValue => {
                return onChange?.(newValue.value)
            }}
        />
    )
}

export default forwardRef(BooleanSelect)
