import { CellContext, Column, Table as ExternalTable, Row, RowData, flexRender } from '@tanstack/react-table'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { ForwardedRef, Fragment, ReactNode, forwardRef, useCallback } from 'react'
import { asObject } from '../../../utils/Helper'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { convertHeadersToColumnAndRowsGrouping } from './TableHeader.utils'
import PlainTable, { PlainTableProps } from '../plain-table/PlainTable'
import PlainTableBody from '../plain-table/PlainTableBody'
import PlainTableBodyRow from '../plain-table/PlainTableBodyRow'
import PlainTableBodyRowCell, { PlainTableBodyRowCellPropsSticky } from '../plain-table/PlainTableBodyRowCell'
import PlainTableHeader from '../plain-table/PlainTableHeader'
import PlainTableHeaderRow from '../plain-table/PlainTableHeaderRow'
import PlainTableHeaderRowCell, { PlainTableHeaderRowCellPropsSticky } from '../plain-table/PlainTableHeaderRowCell'
import TableFilter from './filter/TableFilter'

export type TableHeaderGroupingType = 'columns-and-rows' | 'only-rows'

export type TableProps<TData extends RowData> = PlainTableProps & {
    instance: ExternalTable<TData>
    headerGroupingType?: TableHeaderGroupingType
    loading?: boolean
    striped?: boolean
    getRowSubComponent?: (row: Row<TData>) => ReactNode
    getRowExpandableComponent?: (row: Row<TData>) => ReactNode
    onClickRow?: (row: Row<TData>) => void
    onClickCell?: (cell: CellContext<TData, unknown>) => void
    // You can append custom PlainTableBodyRow components
    appendRows?: ReactNode
    // You can append custom tfoot element
    appendFooter?: ReactNode
    selectOnClickRow?: boolean
    customEmpty?: ReactNode
    hasHeader?: boolean
    hasFiltersRow?: boolean
    isRoundedRow?: boolean
}

const Table = <TData extends RowData>(
    {
        headerGroupingType = 'only-rows',
        striped,
        getRowSubComponent,
        getRowExpandableComponent,
        onClickRow: onClickRowProp,
        onClickCell,
        appendRows,
        appendFooter,
        instance,
        selectOnClickRow,
        customEmpty,
        hasHeader = true,
        hasFiltersRow = true,
        isRoundedRow,
        ...props
    }: TableProps<TData>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const { getHeaderGroups: tableGetHeaderGroups, getRowModel, getFlatHeaders, _getColumnDefs: getColumns } = instance

    const isTableEmpty = () => {
        const rows: Row<TData>[] = getRowModel().rows
        return !rows?.length
    }

    const getHeaderGroups = useCallback(() => {
        if (headerGroupingType === 'only-rows') {
            return tableGetHeaderGroups()
        }

        return convertHeadersToColumnAndRowsGrouping(getFlatHeaders(), getColumns())
    }, [headerGroupingType, getFlatHeaders, getColumns, tableGetHeaderGroups])

    const getSortingIcon = (column: Column<TData>) => {
        const sortDirection = column.getIsSorted()
        if (!column.getCanSort() || !sortDirection) {
            return
        }
        return {
            asc: <ChevronUpIcon className='h-4 w-4' />,
            desc: <ChevronDownIcon className='h-4 w-4' />
        }[sortDirection]
    }

    const getFilterInput = (column: Column<TData>) => {
        if (!column.getCanFilter()) {
            return (
                getFlatHeaders().filter(item => {
                    return item.getContext().column.getCanFilter()
                }).length > 0 && <div style={{ height: 34 }} />
            )
        }
        return (
            <div>
                <TableFilter column={column} table={instance} />
            </div>
        )
    }

    const buildCellClassName = (
        column: Column<TData>,
        isStriped: boolean,
        isHeader: boolean,
        row?: Row<TData>,
        isGroup: boolean = false,
        isPlaceholder: boolean = false
    ) => {
        const meta = column.columnDef.meta as Record<string, unknown>
        const cellClassName = meta?.cellClassName as
            | undefined
            | string
            | ((props: {
                  column: Column<TData>
                  isStriped: boolean
                  isHeader: boolean
                  row?: Row<TData>
                  isGroup: boolean
                  isPlaceholder: boolean
              }) => string)
        if (!meta || !cellClassName) {
            return
        }
        if (typeof cellClassName === 'string') {
            return cellClassName
        }
        return cellClassName({ column, row, isHeader, isStriped, isGroup, isPlaceholder }) || ''
    }

    const onClickRow = (row: Row<TData>) => {
        onClickRowProp?.(row)
        if (selectOnClickRow) {
            instance.setRowSelection(prev => {
                if (
                    Object.entries(prev).find(([key]) => {
                        return key === `${row.index}`
                    })
                ) {
                    return Object.fromEntries(
                        Object.entries(prev).filter(([key]) => {
                            return key !== `${row.index}`
                        })
                    )
                }
                return {
                    ...prev,
                    [row.index]: true
                }
            })
        }
    }

    const renderHeader = () => {
        return (
            <PlainTableHeader>
                {getHeaderGroups().map((headerGroup, groupIndex) => {
                    let previousLeft = 0
                    const isGroup = groupIndex > 0

                    return (
                        <PlainTableHeaderRow key={headerGroup?.id || headerGroup?.level || groupIndex}>
                            {headerGroup.headers.map((header, index) => {
                                const stickyOptions: PlainTableHeaderRowCellPropsSticky = {
                                    enabled:
                                        (header?.column.columnDef.meta as Record<string, boolean>)?.sticky || false,
                                    left: previousLeft,
                                    zIndex: index
                                }

                                previousLeft = previousLeft + header.column.columnDef.size

                                return (
                                    <PlainTableHeaderRowCell
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        rowSpan={header.rowSpan}
                                        className={buildCellClassName(
                                            header.column,
                                            false,
                                            true,
                                            undefined,
                                            isGroup,
                                            header.isPlaceholder
                                        )}
                                        sticky={stickyOptions}
                                        style={{
                                            minWidth: header.column.columnDef.size,
                                            maxWidth: header.column.columnDef.size
                                        }}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <span
                                                    className={buildClassesWithDefault(
                                                        {
                                                            'cursor-pointer': header.column.getCanSort()
                                                        },
                                                        'text-inherit inline-flex items-center gap-1 w-full'
                                                    )}
                                                    onClick={
                                                        header.column.getCanSort()
                                                            ? header.column.getToggleSortingHandler()
                                                            : undefined
                                                    }
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {getSortingIcon(header.column)}
                                                </span>
                                            </>
                                        )}
                                    </PlainTableHeaderRowCell>
                                )
                            })}
                        </PlainTableHeaderRow>
                    )
                })}
            </PlainTableHeader>
        )
    }

    const renderFilters = () => {
        return (
            <PlainTableHeader>
                {getHeaderGroups().map((headerGroup, groupIndex) => {
                    let previousLeft = 0
                    const isGroup = groupIndex > 0

                    return (
                        <PlainTableHeaderRow
                            className='bg-gray-200 h-[80px]'
                            key={headerGroup?.id || headerGroup?.level || groupIndex}
                        >
                            {headerGroup.headers.map((header, index) => {
                                const stickyOptions: PlainTableHeaderRowCellPropsSticky = {
                                    enabled:
                                        (header?.column.columnDef.meta as Record<string, boolean>)?.sticky || false,
                                    left: previousLeft,
                                    zIndex: index
                                }

                                previousLeft = previousLeft + header.column.columnDef.size

                                return (
                                    <PlainTableHeaderRowCell
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        rowSpan={header.rowSpan}
                                        className={buildCellClassName(
                                            header.column,
                                            false,
                                            true,
                                            undefined,
                                            isGroup,
                                            header.isPlaceholder
                                        )}
                                        sticky={stickyOptions}
                                        style={{
                                            minWidth: header.column.columnDef.size,
                                            maxWidth: header.column.columnDef.size
                                        }}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div className='h-full '>{getFilterInput(header.column)}</div>
                                            </>
                                        )}
                                    </PlainTableHeaderRowCell>
                                )
                            })}
                        </PlainTableHeaderRow>
                    )
                })}
            </PlainTableHeader>
        )
    }

    const renderBody = () => {
        if (isTableEmpty() && customEmpty) {
            return (
                <tbody className='h-16'>
                    <tr>
                        <td colSpan={12}>
                            <span className='h-100 flex items-center justify-center text-sm mt-4'>{customEmpty}</span>
                        </td>
                    </tr>
                </tbody>
            )
        }
        return (
            <PlainTableBody>
                {getRowModel().rows.map(row => {
                    const subComponent = getRowSubComponent?.(row)
                    const subExpandableComponent = getRowExpandableComponent?.(row)
                    const isStriped = striped && row.index % 2 === (hasFiltersRow ? 0 : 1)
                    const isExpanded = row.getIsExpanded()
                    let previousLeft = 0

                    return (
                        <Fragment key={row.id}>
                            <PlainTableBodyRow
                                isRounded={isRoundedRow}
                                darken={isStriped}
                                light={!isStriped}
                                onClick={() => {
                                    return onClickRow?.(row)
                                }}
                                className={buildClassesWithDefault({
                                    'cursor-pointer': !!onClickRowProp,
                                    'rounded-lg': true
                                })}
                            >
                                {row.getVisibleCells().map((cell, index) => {
                                    const overflowHidden = asObject(cell.column.columnDef.meta)?.overflowHidden ?? true
                                    const stickyOptions: PlainTableBodyRowCellPropsSticky = {
                                        enabled:
                                            (cell?.column.columnDef.meta as Record<string, boolean>)?.sticky || false,
                                        left: previousLeft,
                                        zIndex: index + 2
                                    }
                                    previousLeft = previousLeft + cell.column.columnDef.size
                                    return (
                                        <PlainTableBodyRowCell
                                            key={cell.id}
                                            sticky={stickyOptions}
                                            className={buildCellClassName(cell.column, isStriped, false, row)}
                                            style={{
                                                maxWidth: cell.column.columnDef.size,
                                                minWidth: cell.column.columnDef.size
                                            }}
                                            onClick={() => {
                                                return onClickCell?.(cell.getContext())
                                            }}
                                        >
                                            <div
                                                className={buildClassesWithDefault(
                                                    {
                                                        'overflow-x-hidden': overflowHidden
                                                    },
                                                    'text-inherit w-full'
                                                )}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        </PlainTableBodyRowCell>
                                    )
                                })}
                            </PlainTableBodyRow>
                            {isExpanded && subExpandableComponent && (
                                <PlainTableBodyRow darken={isStriped} light={!isStriped} isRounded={isRoundedRow}>
                                    <PlainTableBodyRowCell colSpan={row.getVisibleCells().length}>
                                        {subExpandableComponent}
                                    </PlainTableBodyRowCell>
                                </PlainTableBodyRow>
                            )}
                            {subComponent && (
                                <PlainTableBodyRow darken={isStriped} light={!isStriped} isRounded={isRoundedRow}>
                                    <PlainTableBodyRowCell colSpan={row.getVisibleCells().length}>
                                        {subComponent}
                                    </PlainTableBodyRowCell>
                                </PlainTableBodyRow>
                            )}
                        </Fragment>
                    )
                })}
                {appendRows}
            </PlainTableBody>
        )
    }

    return (
        <PlainTable ref={ref} hoverEffect={!isTableEmpty()} {...props}>
            {hasHeader && renderHeader()}
            {hasFiltersRow && renderFilters()}
            {renderBody()}
            {appendFooter}
        </PlainTable>
    )
}

export default forwardRef(Table)
