import * as React from 'react'
import { createContext } from 'react'
import usePopover from './Popover.hook'

type ContextType = ReturnType<typeof usePopover>

const PopoverContext = createContext<ContextType>(null)

export const usePopoverContext = () => {
    const context = React.useContext(PopoverContext)

    if (!context) {
        throw new Error('Popover components must be wrapped in <Popover />')
    }

    return context
}

export default PopoverContext
