import * as React from 'react'
import { Placement } from '@floating-ui/react'
import { PropsWithChildren } from 'react'
import PopoverContext from './Popover.context'
import usePopover from './Popover.hook'

export type PopoverProps = {
    initialOpen?: boolean
    placement?: Placement
    modal?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
    openOnHover?: boolean
}

const Popover = ({ children, modal = false, ...props }: PropsWithChildren<PopoverProps>) => {
    const popover = usePopover({ modal, ...props })

    return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>
}

export default Popover
