import { numberAsBoolean } from './Helper'

export const translationMappedDays: Record<number, string> = {
    1: 'common.distribution-point.day_mon',
    2: 'common.distribution-point.day_tue',
    3: 'common.distribution-point.day_wed',
    4: 'common.distribution-point.day_thu',
    5: 'common.distribution-point.day_fri',
    6: 'common.distribution-point.day_sat',
    7: 'common.distribution-point.day_sun'
}

export const translationMappedDistributionPointTypes: Record<number, string> = {
    0: 'common.distribution-point.type_standard',
    1: 'common.distribution-point.type_vranov',
    2: 'common.distribution-point.type_praha',
    3: 'common.distribution-point.type_next_vranov',
    4: 'common.distribution-point.type_next_praha'
}

export const translateBoolean = (t: (key: string) => string, value: boolean | number) => {
    if (typeof value === 'number') {
        value = numberAsBoolean(value)
    }
    return t(`frontend.shared.boolean.${value}`)
}

export const translateRole = (t: (key: string) => string, value: string): string => {
    switch (value) {
        case 'D':
            return t('frontend.profile.detail.information.role.customer')
        case 'T':
            return t('frontend.profile.detail.information.role.operator')
        case 'F':
            return t('frontend.profile.detail.information.role.company_operator')
        case 'O':
            return t('frontend.profile.detail.information.role.businessman')
        case 'A':
            return t('frontend.profile.detail.information.role.admin')
        default:
            return value
    }
}

export const translateDelivery = (t: (key: string) => string, value: string): string => {
    switch (value) {
        case 'R':
            return t('frontend.profile.customer_detail.other_info.delivery.types.delivery')
        case 'O':
            return t('frontend.profile.customer_detail.other_info.delivery.types.personal')
        case 'S':
            return t('frontend.profile.customer_detail.other_info.delivery.types.express_train')
        default:
            return value
    }
}

export const translatePayment = (t: (key: string) => string, value: string): string => {
    switch (value) {
        case 'H':
            return t('frontend.profile.customer_detail.other_info.payment.types.cash')
        case 'D':
            return t('frontend.profile.customer_detail.other_info.payment.types.cash_on_delivery')
        case 'P':
            return t('frontend.profile.customer_detail.other_info.payment.types.by_bank_transfer')
        default:
            return value
    }
}

export const translateGarageDoorType = (t: (key: string) => string, value: string): string => {
    switch (value) {
        case 'PR':
            return t('frontend.profile.customer_detail.other_info.garage_door.type.sale')
        case 'MO':
            return t('frontend.profile.customer_detail.other_info.garage_door.type.montage')
        default:
            return value
    }
}
