import * as React from 'react'
import { ForwardedRef, HTMLAttributes, ReactElement, ReactNode, Ref, forwardRef, isValidElement } from 'react'
import { useMergeRefs } from '@floating-ui/react'
import { usePopoverContext } from './Popover.context'

export type PopoverTriggerProps = Omit<HTMLAttributes<HTMLElement>, 'children'> & {
    children: ReactNode | ((open: () => void, ref: Ref<HTMLElement>, props: Record<string, unknown>) => ReactNode)
}

const PopoverTrigger = ({ children, ...props }: PopoverTriggerProps, refProp: ForwardedRef<HTMLElement>) => {
    const context = usePopoverContext()
    const childrenRef = (children as unknown as Record<string, unknown>).ref as Ref<HTMLElement>
    const ref = useMergeRefs([context.refs.setReference, refProp, childrenRef])

    if (typeof children === 'function') {
        return children(
            () => {
                context.setOpen(true)
            },
            ref,
            context.getReferenceProps({
                ...props,
                'data-state': context.open ? 'open' : 'closed'
            } as {})
        ) as ReactElement
    }

    if (!isValidElement(children)) {
        throw new Error('PopoverTrigger - Button must be a component.')
    }

    return React.cloneElement(
        children,
        context.getReferenceProps({
            ref,
            ...props,
            ...children.props,
            'data-state': context.open ? 'open' : 'closed'
        })
    )
}

export default forwardRef(PopoverTrigger)
