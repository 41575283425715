import { NumberFormatValues } from 'react-number-format'
import { XIcon } from '@heroicons/react/outline'
import Button from '../button/Button'
import NumericInput from '../numeric-input/NumericInput'
import Popover from '../popover/Popover'
import PopoverClose from '../popover/PopoverClose'
import PopoverContent from '../popover/PopoverContent'
import PopoverTrigger from '../popover/PopoverTrigger'
import React, { FormEvent, ReactNode, useState } from 'react'
import TextField, { TextFieldProps } from '../text-field/TextField'

export type RangeValue = {
    from?: number
    to?: number
}

export type NumberRangeProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'title' | 'disableReadOnlyStyle' | 'forceReadOnly' | 'readOnly' | 'type'
> & {
    title?: ReactNode
    fromLabel?: string
    toLabel?: string
    saveLabel?: string
    clearLabel?: string
    decimalScale?: number
    value?: RangeValue
    onChange?: (value?: RangeValue) => void
}

const NumberRange = ({
    title = 'Select numbers',
    fromLabel = 'From',
    toLabel = 'To',
    saveLabel = 'Apply',
    clearLabel = 'Clear',
    decimalScale = 0,
    value,
    onChange,
    ...textInputProps
}: NumberRangeProps) => {
    const [fromNumber, setFromNumber] = useState<number>(value?.from)
    const [toNumber, setToNumber] = useState<number>(value?.to)
    const inputValue = value ? `${value.from ?? ''} - ${value.to ?? ''}` : ''

    const clearNumbers = () => {
        setFromNumber(undefined)
        setToNumber(undefined)
        onChange?.(undefined)
    }

    const onChangeFromInput = (fromValue: NumberFormatValues) => {
        setFromNumber(fromValue.floatValue ?? undefined)
    }

    const onChangeToInput = (toValue: NumberFormatValues) => {
        setToNumber(toValue.floatValue ?? undefined)
    }

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (typeof fromNumber === 'undefined' && typeof toNumber === 'undefined') {
            onChange?.(undefined)
            return
        }
        onChange?.({
            from: fromNumber,
            to: toNumber
        })
    }

    return (
        <Popover placement='bottom-start'>
            <PopoverTrigger>
                <TextField
                    inputClassName='!border-none'
                    forceReadOnly
                    disableReadOnlyStyle
                    enableRightIconPointerEvents
                    value={inputValue}
                    rightIcon={
                        <button className='text-secondary hover:cursor-pointer' onClick={clearNumbers}>
                            <XIcon />
                        </button>
                    }
                    {...textInputProps}
                />
            </PopoverTrigger>
            <PopoverContent zIndex={1000}>
                <PopoverClose>
                    {close => {
                        return (
                            <form
                                onSubmit={event => {
                                    onSubmit(event)
                                    close()
                                }}
                            >
                                <h6 className='text-secondary text-body-small font-bold'>{title}</h6>
                                <div className='flex flex-col gap-2.5 mt-2'>
                                    <NumericInput
                                        removePaddingTop
                                        srOnlyLabel
                                        size='sm'
                                        allowedDecimalSeparators={['.', ',']}
                                        decimalSeparator=','
                                        decimalScale={decimalScale}
                                        label={fromLabel}
                                        placeholder={fromLabel}
                                        onValueChange={onChangeFromInput}
                                        value={fromNumber}
                                    />
                                    <NumericInput
                                        removePaddingTop
                                        srOnlyLabel
                                        size='sm'
                                        allowedDecimalSeparators={['.', ',']}
                                        decimalSeparator=','
                                        decimalScale={decimalScale}
                                        label={toLabel}
                                        placeholder={toLabel}
                                        onValueChange={onChangeToInput}
                                        value={toNumber}
                                    />
                                </div>
                                <div className='flex justify-between mt-2.5'>
                                    <PopoverClose onClick={clearNumbers}>
                                        <Button size='sm' variant='outlined' color='secondary' type='button'>
                                            {clearLabel}
                                        </Button>
                                    </PopoverClose>
                                    <Button size='sm' type='submit'>
                                        {saveLabel}
                                    </Button>
                                </div>
                            </form>
                        )
                    }}
                </PopoverClose>
            </PopoverContent>
        </Popover>
    )
}

export default NumberRange
